:root {
    --icon-size: 170px;
}

.project-icon {
    height: var(--icon-size);
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}

.project-icon-apple {
    @extend .project-icon;
    width: var(--icon-size);
    border-radius: 22%;
}

.icon--small {
    --icon-size: 100px;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    margin: 2rem 0;
}

.project-card {
    position: relative;
}

.project-card a {
    display: block;
    text-decoration: none;
    padding: 1.5rem;
    transition: all 0.2s ease;
    border-radius: 12px;
}

.project-card a:hover {
    transform: translateY(-1em);
}

.project-image {
    margin-bottom: 1rem;
}

.project-image img {
    display: block;
    margin: 0 auto;
}

.project-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0.5rem 0;
    text-align: center;
}

.project-description {
    color: #666;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    text-align: center;
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin-bottom: 2em;

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.avatar {
    width: 4em;
    border-radius: 50%;
    margin-bottom: 1em;
}

details {
    summary {
        cursor: pointer;
        user-select: none;

        h2,
        h3,
        h4,
        h5 {
            display: inline-block;
        }
    }
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
    .movie-content {
        flex-direction: column !important;
    }

    .comments {
        padding-top: 2em;
        padding-left: 0 !important;
    }
}

.movie-content {
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    // align-items: flex-start;

    .cover {
        flex: 1;
    }

    .comments {
        flex: 1;
        padding-left: 2em;
    }
}

.list-box {
    width: 12em;
    display: inline-block;
    margin: 1em;
}

form {

    input[type="text"],
    input[type="email"] {
        padding: 0.5em;
        width: 15em;
    }

    input[type="submit"] {
        padding: 0.5em;
        width: 8em;
        font-size: 1em;
    }
}